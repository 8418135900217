import * as React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './node_modules/react-image-gallery/styles/css/image-gallery.css';
import './node_modules/react-modal-video/css/modal-video.min.css';
import './node_modules/react-image-gallery/styles/css/image-gallery.css';
import './node_modules/video-react/dist/video-react.css';
import './node_modules/react-input-range/lib/css/index.css';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { configureStore } from './src/store';
import theme from './src/styles/theme';

const queryClient = new QueryClient({
  defaultConfig: {
    queries: {
      retry: false,
    },
    mutations: {
      // ...
    },
  },
});

export const wrapRootElement = ({ element }) => {
  // Instantiating the Redux store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = configureStore();

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <QueryClientProvider client={queryClient}>
              {element}
            </QueryClientProvider>
          </MuiPickersUtilsProvider>
        </CssBaseline>
      </MuiThemeProvider>
    </Provider>
  );
};
