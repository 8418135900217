import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { AuthState, initialAuthState } from '../state';
import * as authActions from '../actions';
import { createAsyncStateReducers } from '../../async';

const authRootReducer = combineReducers<AuthState>({
  token: createReducer<AuthState['token']>(initialAuthState.token, (builder) =>
    builder
      .addCase(authActions.getTokenEmailAsync.success, (token, action) => {
        return action.payload.data;
      })
      .addCase(authActions.getTokenOAuthAsync.success, (token, action) => {
        return action.payload.data;
      })
      .addCase(authActions.getTokenEmailAsync.failure, () => {
        return '';
      })
      .addCase(authActions.getTokenOAuthAsync.failure, () => {
        return '';
      })
      .addCase(authActions.signOut.success, () => {
        return '';
      })
  ),

  tokenAsyncState: createAsyncStateReducers(initialAuthState.tokenAsyncState, [
    authActions.getTokenEmailAsync,
    authActions.getTokenOAuthAsync,
  ]),
});

export default authRootReducer;
