import SourceSansProRegular from '../assets/fonts/SourceSansPro-Regular.ttf';
import SourceSansProItalic from '../assets/fonts/SourceSansPro-Italic.ttf';
import SourceSansProBold from '../assets/fonts/SourceSansPro-Bold.ttf';
import SourceSansProBoldItalic from '../assets/fonts/SourceSansPro-BoldItalic.ttf';
import SourceSansProSemiBold from '../assets/fonts/SourceSansPro-SemiBold.ttf';
import SourceSansProSemiBoldItalic from '../assets/fonts/SourceSansPro-SemiBoldItalic.ttf';

interface FontFamily {
  fontFamily: string;
  fontStyle: string;
  fontDisplay: 'auto' | 'block' | 'swap' | 'fallback' | 'optional' | undefined;
  fontWeight: string;
  src: string;
}

export const sourceSansProRegular: FontFamily = {
  fontFamily: '"Source Sans Pro"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `
    url(${SourceSansProRegular}) format('truetype')
  `,
};

export const sourceSansProItalic: FontFamily = {
  fontFamily: '"Source Sans Pro"',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `
    url(${SourceSansProItalic}) format('truetype')
  `,
};

export const sourceSansProBold: FontFamily = {
  fontFamily: '"Source Sans Pro"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `
    url(${SourceSansProBold}) format('truetype')
  `,
};

export const sourceSansProBoldItalic: FontFamily = {
  fontFamily: '"Source Sans Pro"',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `
    url(${SourceSansProBoldItalic}) format('truetype')
  `,
};

export const sourceSansProSemiBold: FontFamily = {
  fontFamily: '"Source Sans Pro"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: `
    url(${SourceSansProSemiBold}) format('truetype')
  `,
};

export const sourceSansProSemiBoldItalic: FontFamily = {
  fontFamily: '"Source Sans Pro"',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: `
    url(${SourceSansProSemiBoldItalic}) format('truetype')
  `,
};
