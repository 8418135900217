const colors = {
  primary1: {
    light: '#ff5f4e',
    main: '#ed1c24',
    dark: '#b20000',
    contrastText: '#ffffff',
  },

  primary2: {
    light: '#000000',
    main: '#000000',
    dark: '#000000',
    contrastText: '#ffffff',
  },

  support1: {
    light: '#ffa180',
    main: '#f37053',
    dark: '#bb4029',
    contrastText: '#000000',
  },

  support2: {
    light: '#6884da',
    main: '#3258a8',
    dark: '#003078',
    contrastText: '#ffffff',
  },

  support3: {
    light: '#95f7e5',
    main: '#62c4b3',
    dark: '#2b9383',
    contrastText: '#000000',
  },

  support3Light: {
    light: '#c9ffff',
    main: '#95f7e5',
    dark: '#62c4b3',
    contrastText: '#000000',
  },

  support4: {
    light: '#fff175',
    dark: '#c78f01',
    main: '#febf43',
    contrastText: '#000000',
  },

  support5: {
    light: '#c3c6c9',
    main: '#939598',
    dark: '#707070',
    contrastText: '#414141',

    light1: '#b2b2b2',
    light2: '#f3f3f3',
  },

  support5Light: {
    light: '#f3f3f3',
    main: '#939598',
    dark: '#707070',
    contrastText: '#212121',
  },
};

export default colors;
