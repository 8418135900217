import { createTheme, responsiveFontSizes } from '@material-ui/core';
import {
  sourceSansProRegular,
  sourceSansProItalic,
  sourceSansProBold,
  sourceSansProBoldItalic,
  sourceSansProSemiBold,
  sourceSansProSemiBoldItalic,
} from './fonts';
import colors from './colors';

import './main.css';
import './react-tag.css';

// Extending MUI theme:
// https://www.bergqvist.it/blog/2020/6/26/extending-theme-material-ui-with-typescript
// https://material-ui.com/guides/typescript/#customization-of-theme

type FontWeight = 'normal' | 'bold' | 'lighter' | 'bolder' | number;

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    mm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface FontStyle {
    fontWeightSemiBold: FontWeight;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface SimplePaletteColorOptions {
    light1?: string;
    light2?: string;
    dark1?: string;
    dark2?: string;
  }

  interface PaletteOptions {
    orange: SimplePaletteColorOptions;
    teal: SimplePaletteColorOptions;
    yellow: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions;
    grayLight: SimplePaletteColorOptions;
    black: SimplePaletteColorOptions;
    tealLight: SimplePaletteColorOptions;
  }

  interface Palette {
    orange: SimplePaletteColorOptions;
    teal: SimplePaletteColorOptions;
    yellow: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions;
    grayLight: SimplePaletteColorOptions;
    black: SimplePaletteColorOptions;
    tealLight: SimplePaletteColorOptions;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    fontWeightRegular: FontWeight;
    fontWeightSemiBold: FontWeight;
    fontWeightBold: FontWeight;
  }

  interface TypographyOptions {
    fontWeightRegular: FontWeight;
    fontWeightSemiBold: FontWeight;
    fontWeightBold: FontWeight;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    custom: {
      container1770?: string | number;
      container1460?: string | number;
      headerMaxWidth?: string | number;
      navDrawerWidth?: string | number;
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    custom: {
      container1770?: string | number;
      container1460?: string | number;
      headerMaxWidth?: string | number;
      navDrawerWidth?: string | number;
    };
  }
}

// Create the theme object
// https://material-ui.com/customization/theming/#createmuitheme-options-args-theme
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mm: 780,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    primary: colors.primary1,
    secondary: colors.support2,
    orange: colors.support1,
    teal: colors.support3,
    yellow: colors.support4,
    gray: colors.support5,
    grayLight: colors.support5Light,
    black: colors.primary2,
    tealLight: colors.support3Light,
  },

  typography: {
    fontFamily: `"Source Sans Pro", sans-serif`,

    fontWeightRegular: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,

    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1.125rem',
    },
    subtitle1: {
      fontSize: '0.875rem',
    },
    subtitle2: {
      fontSize: '0.75rem',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 82,
    },
  },

  custom: {
    container1770: 1770,
    container1460: 1460,
    headerMaxWidth: 1280,
    navDrawerWidth: 250,
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          sourceSansProRegular,
          sourceSansProItalic,
          sourceSansProBold,
          sourceSansProBoldItalic,
          sourceSansProSemiBold,
          sourceSansProSemiBoldItalic,
        ],
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
});

// Create responsive font sizes for the theme
// https://material-ui.com/customization/theming/#responsivefontsizes-theme-options-theme
theme = responsiveFontSizes(theme);

export default theme;
