import {
  ActionReducerMapBuilder,
  createReducer,
  PayloadActionCreator,
} from '@reduxjs/toolkit';

export enum AsyncStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface AsyncState {
  status: AsyncStatus;
  error: Error | null;
}

export interface AsyncAction {
  request: PayloadActionCreator<any, any>;
  loading: PayloadActionCreator<any, any>;
  success: PayloadActionCreator<any, any>;
  failure: PayloadActionCreator<any, any>;
  cancel: PayloadActionCreator<any, any>;
}

export const addAsyncStateReducerCases = (
  builder: ActionReducerMapBuilder<AsyncState>,
  asyncAction: AsyncAction
) =>
  builder
    .addCase(asyncAction.request, (asyncState) => {
      asyncState.status = AsyncStatus.LOADING;
      asyncState.error = null;
    })
    .addCase(asyncAction.loading, (asyncState) => {
      asyncState.status = AsyncStatus.LOADING;
      asyncState.error = null;
    })
    .addCase(asyncAction.success, (asyncState) => {
      asyncState.status = AsyncStatus.SUCCESS;
      asyncState.error = null;
    })
    .addCase(asyncAction.failure, (asyncState, action) => {
      asyncState.status = AsyncStatus.ERROR;
      asyncState.error = action.payload;
    })
    .addCase(asyncAction.cancel, (networkState) => {
      networkState.status = AsyncStatus.IDLE;
      networkState.error = null;
    });

export const createAsyncStateReducer = (
  initialState: AsyncState,
  asyncAction: AsyncAction
) =>
  createReducer(initialState, (builder) =>
    addAsyncStateReducerCases(builder, asyncAction)
  );

export const createAsyncStateReducers = (
  initialState: AsyncState,
  asyncActions: AsyncAction[]
) =>
  createReducer(initialState, (builder) => {
    for (const action of asyncActions) {
      addAsyncStateReducerCases(builder, action);
    }
  });
