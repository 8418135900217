import { AsyncState, AsyncStatus } from '../async';

export interface AuthState {
  token: string;

  tokenAsyncState: AsyncState;
}

export const initialAuthState: AuthState = {
  token: '',

  tokenAsyncState: {
    status: AsyncStatus.IDLE,
    error: null,
  },
};
