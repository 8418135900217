export enum RentDisplayType {
  PCM = 'pcm',
  PW = 'pw',
}

export enum DistanceDisplayType {
  METRIC,
  IMPERIAL,
}

export interface SiteConfigState {
  rentDisplayType: RentDisplayType;
  distanceDisplayType: DistanceDisplayType;

  // Should be parsable by Intl.NumberFormat
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  currency: string;
}

export const initialSiteConfigState: SiteConfigState = {
  rentDisplayType: RentDisplayType.PCM,
  distanceDisplayType: DistanceDisplayType.METRIC,
  currency: 'GBP',
};
