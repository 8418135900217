import {
  combineReducers,
  configureStore as reduxToolkitConfigureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { initialSiteConfigState } from './siteConfig/state';
import siteConfigRootReducer from './siteConfig/reducers';
import { initialAuthState } from './auth/state';
import authRootReducer from './auth/reducers';
import { AppState } from './types';

// ========== STORE CONFIGURATION ========== //

const initialAppState: AppState = {
  auth: initialAuthState,
  siteConfig: initialSiteConfigState,
};

const rootReducer = combineReducers<AppState>({
  auth: authRootReducer,
  siteConfig: siteConfigRootReducer,
});

const middleware = getDefaultMiddleware();
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { logger } = require('redux-logger');

  middleware.push(logger);
}

export const configureStore = () => {
  const store = reduxToolkitConfigureStore({
    reducer: rootReducer,
    preloadedState: initialAppState,
    middleware,
  });

  return store;
};

type Store = ReturnType<typeof configureStore>;

export type RootState = ReturnType<Store['getState']>;

export type AppDispatch = Store['dispatch'];
