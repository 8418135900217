import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { initialSiteConfigState, SiteConfigState } from '../state';
import * as siteConfigActions from '../actions';

const siteConfigRootReducer = combineReducers<SiteConfigState>({
  rentDisplayType: createReducer<SiteConfigState['rentDisplayType']>(
    initialSiteConfigState.rentDisplayType,
    (builder) =>
      builder.addCase(
        siteConfigActions.updateRentDisplayType,
        (rentDisplayType, action) => {
          return action.payload;
        }
      )
  ),

  distanceDisplayType: createReducer<SiteConfigState['distanceDisplayType']>(
    initialSiteConfigState.distanceDisplayType,
    (builder) =>
      builder.addCase(
        siteConfigActions.updateDistanceDisplayType,
        (distanceDisplayType, action) => {
          return action.payload;
        }
      )
  ),

  currency: createReducer<SiteConfigState['currency']>(
    initialSiteConfigState.currency,
    (builder) =>
      builder.addCase(siteConfigActions.updateCurrency, (currency, action) => {
        return action.payload;
      })
  ),
});

export default siteConfigRootReducer;
