import { createAction } from '@reduxjs/toolkit';
import { DistanceDisplayType, RentDisplayType } from '../state';

/**
 * UPDATE RENT DISPLAY TYPE
 */

type UpdateRentDisplayTypePayload = RentDisplayType;

export const updateRentDisplayType = createAction<
  UpdateRentDisplayTypePayload,
  '@@SITE_CONFIG/UPDATE_RENT_DISPLAY_TYPE'
>('@@SITE_CONFIG/UPDATE_RENT_DISPLAY_TYPE');

/**
 * UPDATE DISTANCE DISPLAY TYPE
 */

type UpdateDistanceDisplayTypePayload = DistanceDisplayType;

export const updateDistanceDisplayType = createAction<
  UpdateDistanceDisplayTypePayload,
  '@@SITE_CONFIG/UPDATE_DISTANCE_DISPLAY_TYPE'
>('@@SITE_CONFIG/UPDATE_DISTANCE_DISPLAY_TYPE');

/**
 * UPDATE CURRENCY
 */

type UpdateCurrencyPayload = string;

export const updateCurrency = createAction<
  UpdateCurrencyPayload,
  '@@SITE_CONFIG/UPDATE_CURRENCY'
>('@@SITE_CONFIG/UPDATE_CURRENCY');
