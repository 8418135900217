import { createAction } from '@reduxjs/toolkit';
import { MyDetails, AuthStatus } from '../models';

/**
 * GET TOKEN EMAIL ASYNC
 * ---------------------
 * Get an access token by authenticating the user using email and password.
 */

export interface GetTokenEmailRequestPayload {
  email: string;
  password: string;
}

export interface GetTokenEmailSuccessPayload {
  data: string; // This is the access token
}

export const getTokenEmailAsync = {
  request: createAction<
    GetTokenEmailRequestPayload,
    '@AUTH/GET_TOKEN_EMAIL/REQUEST'
  >('@AUTH/GET_TOKEN_EMAIL/REQUEST'),
  loading: createAction<undefined, '@AUTH/GET_TOKEN_EMAIL/LOADING'>(
    '@AUTH/GET_TOKEN_EMAIL/LOADING'
  ),
  success: createAction<
    GetTokenEmailSuccessPayload,
    '@AUTH/GET_TOKEN_EMAIL/SUCCESS'
  >('@AUTH/GET_TOKEN_EMAIL/SUCCESS'),
  failure: createAction<Error, '@AUTH/GET_TOKEN_EMAIL/FAILURE'>(
    '@AUTH/GET_TOKEN_EMAIL/FAILURE'
  ),
  cancel: createAction<undefined, '@AUTH/GET_TOKEN_EMAIL/CANCEL'>(
    '@AUTH/GET_TOKEN_EMAIL/CANCEL'
  ),
};

/**
 * GET TOKEN OAUTH ASYNC
 * ---------------------
 * Get an access token by authenticating the user using an OAuth service.
 */

export interface GetTokenOAuthRequestPayload {
  service: string;
  email: string;
}

export interface GetTokenOAuthSuccessPayload {
  data: string; // This is the access token
}

export const getTokenOAuthAsync = {
  request: createAction<
    GetTokenOAuthRequestPayload,
    '@AUTH/GET_TOKEN_OAUTH/REQUEST'
  >('@AUTH/GET_TOKEN_OAUTH/REQUEST'),
  loading: createAction<undefined, '@AUTH/GET_TOKEN_OAUTH/LOADING'>(
    '@AUTH/GET_TOKEN_OAUTH/LOADING'
  ),
  success: createAction<
    GetTokenOAuthSuccessPayload,
    '@AUTH/GET_TOKEN_OAUTH/SUCCESS'
  >('@AUTH/GET_TOKEN_OAUTH/SUCCESS'),
  failure: createAction<Error, '@AUTH/GET_TOKEN_OAUTH/FAILURE'>(
    '@AUTH/GET_TOKEN_OAUTH/FAILURE'
  ),
  cancel: createAction<undefined, '@AUTH/GET_TOKEN_OAUTH/CANCEL'>(
    '@AUTH/GET_TOKEN_OAUTH/CANCEL'
  ),
};

/**
 * SIGN OUT
 * --------
 * Sign the user out.
 */

export interface SignOutRequestPayload {
  service: string;
  authToken: string;
}

export type SignOutSuccessPayload = Record<string, unknown>;

export const signOut = {
  request: createAction<undefined, '@AUTH/SIGN_OUT'>('@AUTH/SIGN_OUT'),
  loading: createAction<undefined, '@AUTH/SIGN_OUT/LOADING'>(
    '@AUTH/SIGN_OUT/LOADING'
  ),
  success: createAction<undefined, '@AUTH/SIGN_OUT/SUCCESS'>(
    '@AUTH/SIGN_OUT/SUCCESS'
  ),
  failure: createAction<Error, '@AUTH/SIGN_OUT/FAILURE'>(
    '@AUTH/SIGN_OUT/FAILURE'
  ),
  cancel: createAction<undefined, '@AUTH/SIGN_OUT/CANCEL'>(
    '@AUTH/SIGN_OUT/CANCEL'
  ),
};

/**
 * SET AUTHENTICATION STATUS
 * -------------------------
 */

export const setAuthenticationStatus = createAction<
  AuthStatus,
  '@AUTH/SET_AUTH_STATUS'
>('@AUTH/SET_AUTH_STATUS');

/**
 * SET AUTHENTICATION TOKEN
 * -------------------------
 */

export const setAuthenticationToken = createAction<
  string,
  '@AUTH/SET_AUTH_TOKEN'
>('@AUTH/SET_AUTH_TOKEN');

/**
 * SET AUTHENTICATION STATUS
 * -------------------------
 */

export const setAuthenticationProfile = createAction<
  MyDetails | null,
  '@AUTH/SET_AUTH_PROFILE'
>('@AUTH/SET_AUTH_PROFILE');
